<template>
    <v-card class="ma-auto">
        <MyCardTitle modulo="Equipe multidiciplinar" :card-info="pagamento" />
        <v-form ref="form_dados_bancarios">
            <v-card-text>
                <v-row>
                    <v-col cols="12">
                        <v-divider class="mb-3"></v-divider>
                    </v-col>
                    <v-col cols="10">Dados Bancários</v-col>
                    <v-col class="text-end" cols="2">id</v-col>
                </v-row>
                <v-row dense align="center">
                    <v-col :xl="2" :sm="2" :md="2" :lg="2" :cols="12">
                        
                        <v-checkbox 
                            label="Prefencialmente PIX"
                            v-model="pagamento.preferencialmente"
                        >
                        </v-checkbox>
                        <v-checkbox 
                            label="Prefencialmente Depósite nesta conta"
                            v-model="pagamento.deposite"
                        >
                        </v-checkbox>
                        <v-checkbox 
                            label="P. Física"
                            v-model="pagamento.pf"
                        >
                        </v-checkbox>
                        <v-checkbox 
                            label="P. Jurídica"
                            v-model="pagamento.pj"
                        >
                        </v-checkbox>
                    </v-col>
                    <v-col :xl="2" :sm="4" :md="4" :lg="2" :cols="12">
                        <v-checkbox 
                            label="C. Corrente"
                            v-model="pagamento.cc"
                        >
                        </v-checkbox>
                        <v-checkbox 
                            label="C. Poupança"
                            v-model="pagamento.cp"
                        >
                        </v-checkbox>
                        <v-checkbox 
                            :label="ativa"
                            :color="`green`"
                            v-model="pagamento.ativa"
                            v-show="pagamento.id"
                        >
                        </v-checkbox>
                    </v-col>
                    <v-col :xl="7" :sm="7" :md="7" :lg="7" :cols="12">
                        <v-row dense>
                            <v-col :xl="4" :sm="4" :md="4" :lg="4" :cols="12">
                                <v-autocomplete
                                    required
                                    :rules="[e=> !!e||'Obrigatório']"
                                    label="Banco"
                                    v-model="pagamento.banco"
                                    :items="bancos"
                                    item-text="nome"
                                    item-value="id"
                                    outlined
                                    clearable
                                >
                                </v-autocomplete>
                            </v-col>
                            <v-col :xl="3" :sm="3" :md="3" :lg="3" :cols="12">
                                <v-text-field
                                    label="Agência"
                                    v-model="pagamento.agencia"
                                    outlined
                                >
                                </v-text-field>
                            </v-col>
                            <v-col :xl="3" :sm="3" :md="3" :lg="3" :cols="12">
                                <v-text-field
                                    label="Conta"
                                    v-model="pagamento.conta"
                                    outlined
                                >
                                </v-text-field>
                            </v-col>
                            <v-col :xl="2" :sm="2" :md="2" :lg="2" :cols="12">
                                <v-text-field
                                    label="Dígito"
                                    v-model="pagamento.digito"
                                    outlined
                                >
                                </v-text-field>
                            </v-col>
                        </v-row>
                        <v-row dense>
                            <v-col :xl="12" :sm="12" :md="12" :lg="12" :cols="12"> 
                                <v-text-field
                                    v-model="pagamento.pix"
                                    outlined
                                    hide-details
                                    label="Pix"
                                ></v-text-field>
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col v-if="pagamento.id" :xl="1" :sm="1" :md="1" :lg="1" :cols="12" class="text-right"> 
                        <v-btn
                            icon
                            @click="cancela()"
                        >
                            <v-icon size="40">mdi-cancel</v-icon>
                        </v-btn>
                        <v-btn
                            icon
                            @click="post_update(false)"
                            :disabled="disabled_save"
                            :loading="loading_save"
                        >
                            <v-icon size="40">mdi-content-save</v-icon>
                        </v-btn>
                    </v-col>
                    <v-col v-else :xl="12" :sm="1" :md="1" :lg="1" :cols="12" class="text-right">    
                        <v-btn
                            icon
                            @click="post_update(true)"
                            :disabled="disabled_save"
                            :loading="loading_save"
                        >
                            <v-icon size="40">mdi-content-save</v-icon>
                        </v-btn>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <v-btn icon @click="get_pagamentos()"><v-icon>mdi-refresh</v-icon></v-btn>
                        <v-data-table
                            :loading="loading"
                            :headers="headers"
                            :items="pagamentos"
                            :single-expand="singleExpand"
                            :expanded.sync="expanded"
                            show-expand
                        >
                            <template v-slot:expanded-item="{ headers, item }">
                                <td :colspan="headers.length">
                                    <v-chip v-if="item.pf===true">P. FÍSICA</v-chip>
                                    <v-chip v-if="item.pj===true">P. JURÍDICA</v-chip>
                                    <v-chip v-show="item.cp===true">POUPANÇA</v-chip>
                                    <v-chip v-show="item.cc===true">CORRENTE</v-chip>
                                </td>
                            </template>
                            <template
                                v-slot:[`item.deposite`]="{item}"
                            > 
                                <v-chip v-show="item.deposite===true"
                                    color="blue"
                                    class="white--text"
                                >DEPOSITE NESTA CONTA</v-chip>
                            </template>
                            <template
                                v-slot:[`item.preferencialmente`]="{item}"
                            > 
                                <v-chip v-show="item.preferencialmente===true">SIM</v-chip>
                            </template>
                            <template
                                v-slot:[`item.ativa`]="{item}"
                            > 
                                <v-chip v-if="item.ativa===true"
                                    color="blue"
                                    class="white--text"
                                >ATIVA</v-chip>
                                <v-chip v-else>INATIVA</v-chip>
                            </template>
                            <template 
                                v-slot:[`item.acoes`]="{item}"
                            >
                                <v-btn icon @click="edit(item)">
                                    <v-icon>mdi-pencil</v-icon>
                                </v-btn>
                            </template>
                        </v-data-table>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-form>
    </v-card>
</template>
<script>
import { mapGetters } from 'vuex'
import api from '@/http'
export default{
    name:'DadosBancarios',
    components:{
        MyCardTitle: () => import("../../../uteis/card_title.vue")
    },
    data:()=>({
        expanded: [],
        singleExpand: false,
        loading_save: false,
        headers:[
            {text:"Id", align:"center", value:"id"},
            {text:"Banco", align:"start", value:"banco.nome"},
            {text:"Agência", align:"start", value:"agencia"},
            {text:"Conta", align:"start", value:"conta"},
            {text:"Dig", align:"start", value:"digito"},
            {text:"PIX", align:"start", value:"pix"},
            {text:"Deposite", align:"center", value:"deposite"},
            {text:"Prefe PIX", align:"center" ,value:"preferencialmente"},
            {text:"Ativa", align:"center", value:"ativa"},
            {text:"Editar", align:"center", value:"acoes"},
        ],
        pagamentos:[],
        pagamento:{
            preferencialmente: false,
            pf: false,
            pj: false,
            deposite: false,
            ativa: true,
            cp: false,
            cc: false,
            banco: "",
            agencia: "",
            conta: "",
            digito: "",
            pix: ""
        },
        bancos:[],
        agencias:[],
        bool_tipo_conta: false,
        loading: false,
    }),
    methods:{
        get_pagamentos(){
            api(`contas/formas-pagamentos/?usuario=${this.pessoa.id}`)
            .then((e)=>{this.pagamentos=e.data})
        },
        get_banco(){
            api('contas/bancos/').then((e)=>{this.bancos=e.data})
        },
        get_agencia(obj){
            api(`contas/agencias/?banco=${obj.id}`).then((e)=>{this.agencias=e.data})
        },
        post_update(post=true){
            this.loading = true;
            this.loading_save = true;
            if(this.$refs.form_dados_bancarios.validate()){
                this.pagamento.usuario = this.pessoa.id
                if(post){
                    api.post(`contas/formas-pagamentos/`, this.pagamento)
                    .then(()=>{ this.get_pagamentos() })
                    .catch((e)=>{console.log(e)})
                    .finally(()=>{
                        this.loading = false
                        this.loading_save = false
                    })
                }else{
                    api.patch(`contas/forma-pagamento/${this.pagamento.id}/`, this.pagamento)
                    .then(()=>{ this.get_pagamentos() })
                    .catch((e)=>{console.log(e)})
                    .finally(()=>{
                        this.loading = false
                        this.loading_save = false
                    })
                }
            }
        },
        edit(obj){
            this.pagamento = obj
        },
        cancela(){
            this.pagamento = {
                preferencialmente: false,
                pfj: false,
                deposite: false,
                ativa: true,
                cp: false,
                cc: false,
                banco: "",
                agencia: "",
                conta: "",
                digito: "",
                pix: ""
            }
        }
    },
    computed:{
        ...mapGetters(['pessoa']),
        ativa(){
            return this.pagamento.ativa?'ATIVA':'INATIVA'
        },
        disabled_save(){
            return !this.pagamento.banco ? true : false
        }
    },
    watch:{
        
    },
    mounted(){
        this.get_banco()
        this.get_pagamentos()
    }
}
</script>